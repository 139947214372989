import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export abstract class ApiClient {
  public constructor(
    private http: HttpClient,
  ) {

  }

  public get<T>(path: string, params: any = null) {
    const httpOptions = {
      params,
    };
    return this.http.get<T>(this.getApiUrl(path), httpOptions);
  }

  public put<T, D>(path: string, data: D, params: any = null) {
    const httpOptions = {
      params,
    };
    return this.http.put<T>(this.getApiUrl(path), data, httpOptions);
  }

  public post<T, D>(path: string, data: D, params: any = null) {
    const httpOptions = {
      params,
    };
    return this.http.post<T>(this.getApiUrl(path), data, httpOptions)
  }

  public getApiUrl(path: string) {
    return `${environment.apiUrl}/master/api/v1${path}`
  }
}
