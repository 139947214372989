import { Component } from '@angular/core';
import {MatCard} from "@angular/material/card";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatPaginator} from "@angular/material/paginator";
import {MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatTable} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {FormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {ApiClient} from "../services/api/api.service";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    MatCard,
    MatProgressSpinner,
    MatPaginator,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatFormField,
    FormsModule,
    MatLabel,
    MatButton,
    MatInput,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  number = '+33000000000';

  scenario = 'Tu es un démarcheur téléphonique qui appelle au nom de la société "Isolation Plus".\n' +
    'Tu dois demander les informations suivantes : "Type de logement" "surface approximative du logement" "Type de chauffage" "Nombre de fenêtres" "si les fenêtres sont en double vitrage (ou mieux)"\n' +
    'Poses les questions une par une.';

  constructor(private apiService : ApiClient){

  }

  async callNumber() {
    await firstValueFrom(this.apiService.post("/call",{Number : this.number, Scenario: this.scenario}))
    console.log("test");
  }
}
