import { Pipe, PipeTransform } from '@angular/core';
import {CallStatus} from "../models/call-status.enum";

@Pipe({
  name: 'callStatus',
  standalone: true
})
export class CallStatusPipe implements PipeTransform {

  transform(value: CallStatus | undefined): string {
    if(value === undefined) {
      return 'Unknown';
    }

    switch (value) {
      case CallStatus.ToDo:
        return 'A faire';
      case CallStatus.Pending:
        return 'En attente';
      case CallStatus.InProgress:
        return 'En cours';
      case CallStatus.Finished:
        return 'Terminé';
      default:
        return 'Erreur';
    }
  }

}
