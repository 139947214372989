<div class="">
  <h2 class="mat-h2 m-3">Liste des scénarios</h2>

  <div class="d-flex align-items-center justify-content-start my-3">
    <button mat-raised-button color="accent" (click)="navigateToCreate()">Créer un nouveau scénario</button>
  </div>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Identifiant </th>
        <td mat-cell *matCellDef="let scenario"> {{scenario.idScenario}} </td>
      </ng-container>

      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Titre </th>
        <td mat-cell *matCellDef="let scenario"> {{scenario.title}} </td>
      </ng-container>

      <!-- CreationDate Column -->
      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef> Date de création </th>
        <td mat-cell *matCellDef="let scenario"> {{scenario.creationDate | date:'medium'}} </td>
      </ng-container>

      <!-- Prompt Column -->
      <ng-container matColumnDef="prompt">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let scenario"> {{scenario.prompt}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
