import { Pipe, PipeTransform } from '@angular/core';
import {ResultCallStatus} from "../models/result-call-status.enum";

@Pipe({
  name: 'resultCallStatus',
  standalone: true
})
export class ResultCallStatusPipe implements PipeTransform {

  transform(value: ResultCallStatus | undefined): string {
    if(value === undefined) {
      return 'Initial';
    }

    switch (value) {
      case ResultCallStatus.Success:
        return 'Success';
      case ResultCallStatus.ExplicitRefusal:
        return 'Appel refusé';
      case ResultCallStatus.NoResponse:
        return 'Pas de réponse';
      case ResultCallStatus.InaccessibleNumber:
        return 'Numéro non attribué';
      default:
        return 'Unknown';
    }
  }

}
