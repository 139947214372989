import {Component, OnInit} from '@angular/core';
import {MatCard} from "@angular/material/card";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatPaginator} from "@angular/material/paginator";
import {MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatTable} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {ApiClient} from "../services/api/api.service";
import {firstValueFrom} from "rxjs";
import {MatOption, MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    MatCard,
    MatProgressSpinner,
    MatPaginator,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatFormField,
    FormsModule,
    MatLabel,
    MatButton,
    MatInput,
    MatSelect,
    MatOption,
    ReactiveFormsModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  number = '+33000000000';

  scenario = 'Tu es un démarcheur téléphonique qui appelle au nom de la société "Isolation Plus".\n' +
    'Tu dois demander les informations suivantes : "Type de logement" "surface approximative du logement" "Type de chauffage" "Nombre de fenêtres" "si les fenêtres sont en double vitrage (ou mieux)"\n' +
    'Poses les questions une par une.';

  model = new FormControl('');

  modelList = ['realtime','deepopenai']

  constructor(private apiService : ApiClient){

  }

  ngOnInit(): void {
      this.scenario = localStorage.getItem('prompt') ?? this.scenario;
    }

  async callNumber() {
    await firstValueFrom(this.apiService.post("/call",{Number : this.number, Scenario: this.scenario, model:this.model.value}))
  }
}
