import {Injectable, signal} from "@angular/core";
import {firstValueFrom} from "rxjs";
import {ApiClient} from "../api/api.service";

@Injectable({
  providedIn: 'root'
})
export abstract class AuthService {

  public isAuthenticated = signal(false);

  protected constructor(
    protected apiClient: ApiClient
  ) {
    this.signInCheck().finally();
  }

  async signInCheck() {
    let isSignedIn = await this.isSignedIn();
    this.isAuthenticated.set(isSignedIn);
  }

  async isSignedIn(): Promise<boolean> {
    try {
      await this.getCurrentUserProfile();
      return true;
    }
    catch (error) {
      return false;
    }
  }

  async getCurrentUserProfile(): Promise<any> {

    return await firstValueFrom(this.apiClient.get('/account/manage/info', null));
  }

  async login(email: string, password: string) {
    await firstValueFrom(this.apiClient.post("/account/login?useCookies=true&useSessionCookies=false",{email : email, Password: password}));
    await this.signInCheck();
  }

  async logout() {
    await firstValueFrom(this.apiClient.post("/account/logout", null));
    await this.signInCheck();
  }
}
