<div class="container d-flex justify-content-center align-items-center vh-100">
  <div class="mat-elevation-z8 my-5">
    <form (ngSubmit)="login()" class="m-3 d-flex flex-column">
      <h3 class="mat-h1">se connecter</h3>

      <mat-form-field appearance="fill">
        <mat-label>email</mat-label>
        <input matInput [(ngModel)]="email" name="email" type="email" required>
      </mat-form-field>

      <mat-form-field appearance="fill" >
        <mat-label>mots de passe</mat-label>
        <input matInput [(ngModel)]="password" name="password" type="password" required>
      </mat-form-field>


      <button mat-raised-button color="primary" class="mx-3" type="submit">connecter</button>

    </form>
  </div>
</div>
