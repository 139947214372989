import {Component, Inject, OnInit} from '@angular/core';
import {Contact} from "../common/models/contact";
import {CallStatusPipe} from "../common/pipe/call-status.pipe";
import {ResultCallStatusPipe} from "../common/pipe/result-call-status.pipe";
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {MatButton} from "@angular/material/button";
import {MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardImage} from "@angular/material/card";
import {AudioPlayerComponent} from "../audio-player/audio-player.component";
import {ResultCallStatus} from "../common/models/result-call-status.enum";

@Component({
  selector: 'app-contact-details',
  standalone: true,
  imports: [
    CallStatusPipe,
    ResultCallStatusPipe,
    MatButton,
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatCardActions,
    MatCardImage,
    AudioPlayerComponent,
    MatDialogContent,
  ],
  templateUrl: './contact-details.component.html',
  styleUrl: './contact-details.component.scss'
})
export class ContactDetailsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ContactDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public contact: Contact
  ) {
  }

  ngOnInit() {
    console.log(this.contact);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  protected readonly ResultCallStatus = ResultCallStatus;
}
