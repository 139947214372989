import {Component, OnInit, ViewChild} from '@angular/core';
import {MatList, MatListItem} from "@angular/material/list";
import {MatCard} from "@angular/material/card";
import {NgForOf, NgIf} from "@angular/common";
import {MatLine} from "@angular/material/core";
import {MatPaginator} from "@angular/material/paginator";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatRow,
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from "@angular/material/table";
import {MatSort, MatSortHeader} from "@angular/material/sort";
import {Contact} from "../common/models/contact";
import {CallStatus} from "../common/models/call-status.enum";
import {CallStatusPipe} from "../common/pipe/call-status.pipe";
import {MatCheckbox} from "@angular/material/checkbox";
import {SelectionModel} from "@angular/cdk/collections";
import {ContactDetailsComponent} from "../contact-details/contact-details.component";
import {MatDialog} from "@angular/material/dialog";
import {ButtonGroupComponent, ButtonToolbarComponent} from "@coreui/angular";
import {Scenario} from "../common/models/scenario";
import {ResultCallStatus} from "../common/models/result-call-status.enum";
import {MatButton} from "@angular/material/button";
import {ContactImportComponent} from "../contact-import/contact-import.component";

@Component({
  selector: 'app-contact-list',
  standalone: true,
  imports: [
    MatListItem,
    MatList,
    MatCard,
    NgForOf,
    MatLine,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatCell,
    MatCellDef,
    MatHeaderCellDef,
    MatSort,
    MatHeaderRow,
    MatRow,
    MatPaginator,
    MatTableModule,
    CallStatusPipe,
    MatCheckbox,
    ContactDetailsComponent,
    NgIf,
    ButtonToolbarComponent,
    ButtonGroupComponent,
    MatButton,
    MatSortHeader
  ],
  templateUrl: './contact-list.component.html',
  styleUrl: './contact-list.component.scss'
})
export class ContactListComponent implements OnInit {
  displayedColumns: string[] = ['select', 'lastName', 'firstName', 'phoneNumber', 'titleScenario', 'callStatus'];
  dataSource: MatTableDataSource<Contact>;
  selection = new SelectionModel<Contact>(true, []);
  selectedRow: Contact | null = null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public dialog: MatDialog) {
    const scenario1: Scenario = {
      idScenario: '1',
      title: 'Société d\'isolation',
      creationDate: new Date(),
      prompt: 'Guider la conversation avec courtoisie tout en restant informel et fluide, de manière à ne pas "perdre" le client en cours d\'appel.\n' +
        'Nous sommes une société d\'isolation et tu es un démarcheur téléphonique, l\'objectif est que tu soutires les informations suivantes: ' +
        'Type de logement, \n' +
        'Type de vitrage, \n' +
        'Le nombre de m² du logement, \n' +
        'Le type de chauffage'
    }
    const contacts: Contact[] = [

      {
        idContact: '1',
        phoneNumber: '1234567890',
        lastName: 'Doe1',
        firstName: 'John',
        scenario: scenario1,
        callStatus: CallStatus.Finished,
        resultCallStatus: ResultCallStatus.Success
      },
      {
        idContact: '2',
        phoneNumber: '1234567890',
        lastName: 'Doe2',
        firstName: 'John',
        scenario: scenario1,
        callStatus: CallStatus.Pending,
        resultCallStatus: undefined
      },
      {
        idContact: '3',
        phoneNumber: '1234567890',
        lastName: 'Doe3',
        firstName: 'John',
        scenario: scenario1,
        callStatus: CallStatus.InProgress,
        resultCallStatus: undefined
      },
      {
        idContact: '4',
        phoneNumber: '1234567890',
        lastName: 'Doe',
        firstName: 'John',
        scenario: scenario1,
        callStatus: CallStatus.ToDo,
        resultCallStatus: undefined
      },
      {
        idContact: '5',
        phoneNumber: '1234567890',
        lastName: 'Doe',
        firstName: 'John',
        scenario: scenario1,
        callStatus: CallStatus.ToDo,
        resultCallStatus: undefined
      },
      {
        idContact: '6',
        phoneNumber: '1234567890',
        lastName: 'Doe',
        firstName: 'John',
        scenario: scenario1,
        callStatus: CallStatus.ToDo,
        resultCallStatus: undefined
      },
      {
        idContact: '7',
        phoneNumber: '1234567890',
        lastName: 'Doe',
        firstName: 'John',
        scenario: scenario1,
        callStatus: CallStatus.ToDo,
        resultCallStatus: undefined
      },
      {
        idContact: '8',
        phoneNumber: '1234567890',
        lastName: 'Doe',
        firstName: 'John',
        scenario: scenario1,
        callStatus: CallStatus.ToDo,
        resultCallStatus: undefined
      },
      {
        idContact: '9',
        phoneNumber: '1234567890',
        lastName: 'Doe',
        firstName: 'John',
        scenario: scenario1,
        callStatus: CallStatus.ToDo,
        resultCallStatus: undefined
      }];
    this.dataSource = new MatTableDataSource(contacts);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  onRowClicked(row: Contact) {
    this.dialog.open(ContactDetailsComponent, {
      data: row
    });
  }

  startCall(){
    console.log('start call');
    console.log(this.getSelectedContactIds());
  }

  import(){
    this.dialog.open(ContactImportComponent, {});
  }

  getSelectedContactIds(): string[] {
    return this.selection.selected.map(contact => contact.idContact);
  }

}
