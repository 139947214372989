

<!--@if(contact){
  <div>
    <h3>Details</h3>
    <p>Numéro de téléphone: {{contact.phoneNumber}}</p>
    <p>Prénom: {{contact.firstName}}</p>
    <p>Nom: {{contact.lastName}}</p>
    <p>Scenari: </p>
    <p> - Titre: {{contact.scenario.title}}</p>
    <p> - Prompt: {{contact.scenario.prompt}}</p>

    <p>Statut: {{contact.callStatus | callStatus }}</p>
    <p>Résulat: {{contact.resultCallStatus | resultCallStatus }}</p>
  </div>
}
<button mat-button (click)="onClose()">Close</button>-->


<mat-card class="example-card" appearance="outlined">
  <mat-card-header>
    <div>
      <h2> Détail du contact</h2>
      <p>Statut: {{contact.callStatus | callStatus }}</p>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-dialog-content>
      @if(contact){
        <div>
          <div>Numéro de téléphone: {{contact.phoneNumber}}</div>
          <div>Prénom: {{contact.firstName}}</div>
          <div>Nom: {{contact.lastName}}</div>
        </div>

        <div>
          <div class="my-3">
            <h5>Scenario: </h5>
            <div>Titre: {{contact.scenario.title}} - (id:{{contact.scenario.idScenario}})</div>
            <div>{{contact.scenario.prompt}} </div>
          </div>
        </div>

        <div>
          <div class="my-3">
            <h5>Resultat [Statut: {{contact.resultCallStatus | resultCallStatus}}]</h5>
            @if (contact.resultCallStatus === ResultCallStatus.Success){
              <div>
                <div>
                  <h5>Transcription textuel</h5>
                  <p>
                    Bonjour, je suis [Prénom] de la société [Nom de l'entreprise], spécialisée dans l’isolation des bâtiments. Comment allez-vous aujourd’hui ? Je vous appelle rapidement pour savoir si vous seriez intéressé à en savoir plus sur des solutions qui peuvent vous permettre de faire des économies d'énergie.

                    Pour bien comprendre votre situation et vous proposer des conseils adaptés, pourriez-vous me dire, vous habitez plutôt une maison ou un appartement ?
                  </p><p class="font-weight-bold"># reponse </p><p>
                    D’accord, et vous savez si vos fenêtres sont en simple, double ou même triple vitrage ?
                  </p><p># reponse </p><p>
                    Super, merci pour ça ! Concernant la taille de votre logement, est-ce que vous avez une idée du nombre de mètres carrés ? Plutôt dans les 80-100, 100-150 ou plus ?
                  </p><p># reponse </p><p>
                    C’est parfait, et pour finir, quel type de chauffage utilisez-vous ? Plutôt au bois, au fioul, au gaz ou électrique ?
                  </p><p># reponse </p><p>
                    Merci beaucoup pour vos réponses, ça me donne une bonne idée de votre situation. Si vous êtes intéressé, on pourrait discuter des solutions d’isolation qui vous permettraient de réduire vos factures d’énergie. Est-ce que ça vous intéresserait ?
                  </p>
                </div>

                <div>
                  <h5>Retranscription audio</h5>
                  <app-audio-player audioSrc="assets/test-audio.mp3"></app-audio-player>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </mat-dialog-content>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button (click)="onClose()">Retour</button>
  </mat-card-actions>
</mat-card>
