<nav class="p-3 justify-content-between">
  <div class="logo-container">
    <a routerLink="/home">
      <img class="logo" src="{{logoUrl}}" alt="logo"/>
    </a>
    <a mat-button routerLink="/home" routerLinkActive="router-active">Accueil</a>
    <a mat-button routerLink="/contact" routerLinkActive="router-active">Liste des contacts</a>
    <a mat-button routerLink="/scenario" routerLinkActive="router-active">Scénario</a>
  </div>


  <div>
    <a *ngIf="!auth.isAuthenticated(), else logout" routerLink="/login">Connexion</a>
  </div>
  <ng-template #logout>
    <a routerLink="/login" (click)="auth.logout()">Déconnexion</a>
  </ng-template>
</nav>
