<div class="mat-elevation-z8 my-5 width-80">

  <h3 class="mat-h1 m-3">Lancer un appel</h3>

  <form (ngSubmit)="callNumber()" class="m-3 d-flex flex-column" >
    <mat-form-field appearance="fill" class="width-40">
      <mat-label>N° de téléphone</mat-label>
      <input matInput [(ngModel)]="number" name="number" required>
    </mat-form-field>

    <mat-form-field appearance="fill" class="prompt" hidden="hidden">
      <mat-label>Scénario</mat-label>
      <textarea matInput [(ngModel)]="scenario" name="scenario" rows="6" disabled></textarea>
    </mat-form-field>

    <div class="d-flex justify-content-end my-2">
      <button mat-raised-button color="primary" class="mx-3" type="submit">Appeler</button>
    </div>
  </form>
</div>

