import {Component, OnInit} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButton} from "@angular/material/button";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";

@Component({
  selector: 'app-prompt',
  standalone: true,
    imports: [
        FormsModule,
        MatButton,
        MatFormField,
        MatInput,
        MatLabel,
        MatOption,
        MatSelect,
        ReactiveFormsModule
    ],
  templateUrl: './prompt.component.html',
  styleUrl: './prompt.component.scss'
})
export class PromptComponent implements OnInit {
  prompt = 'Tu es un démarcheur téléphonique qui appelle au nom de la société "Isolation Plus".\n' +
    'Tu dois demander les informations suivantes : "Type de logement" "surface approximative du logement" "Type de chauffage" "Nombre de fenêtres" "si les fenêtres sont en double vitrage (ou mieux)"\n' +
    'Poses les questions une par une.';
    constructor() {
    }

  ngOnInit(): void {
    this.prompt = localStorage.getItem('prompt') ?? this.prompt;
    }

  saveSignal() {
    localStorage.setItem('prompt',this.prompt);
  }
}
