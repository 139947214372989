import { Component } from '@angular/core';
import {NgIf, NgOptimizedImage} from "@angular/common";
import {MatAnchor} from "@angular/material/button";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {AuthService} from "../services/auth/auth.service";

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    MatAnchor,
    NgOptimizedImage,
    NgIf
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
constructor(protected auth:AuthService) {
}

  get logoUrl(): string {
    return 'assets/callbot.png';
  }
}
