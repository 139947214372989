import { Component } from '@angular/core';
import {FormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatButton} from "@angular/material/button";
import {Router} from "@angular/router";

@Component({
  selector: 'app-create-scenario',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatInput,
    MatButton,
    MatLabel
  ],
  templateUrl: './create-scenario.component.html',
  styleUrl: './create-scenario.component.scss'
})
export class CreateScenarioComponent {
  title: string = '';
  prompt: string = '';

  constructor(private router: Router) {}

  onSubmit(): void {
    console.log('Scenario created:', { title: this.title, prompt: this.prompt });
    this.router.navigate(['/scenario']);
  }

  onBack(): void {
    this.router.navigate(['/scenario']); // Adjust the route as needed
  }
}
