<mat-card class="example-card" appearance="outlined">
  <mat-card-header>
    <div>
      <h2>Import contact</h2>
    </div>
  </mat-card-header>
  <mat-card-content>
    <form class="m-3 d-flex flex-column">
      <input id="fileInput" type="file" (change)="onFileSelected($event)">

      <mat-form-field appearance="fill" class="my-3">
        <mat-label>Associer au scénario</mat-label>
        <mat-select [(value)]="selectedOption">
          <!-- Utilisation de @for pour itérer sur les options -->
          @for (option of options; track option.id) {

            <mat-option [value]="option.id">
              {{ option.id }} - {{ option.value }}
            </mat-option>

          }
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button color="primary" class="mx-3" (click)="import()">Importer</button>
    <button mat-raised-button color="accent" type="button" (click)="onBack()">Retour</button>
  </mat-card-actions>
</mat-card>
