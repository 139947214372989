import {Component} from '@angular/core';
import {AudioPlayerComponent} from "../audio-player/audio-player.component";
import {CallStatusPipe} from "../common/pipe/call-status.pipe";
import {MatButton} from "@angular/material/button";
import {MatCard, MatCardActions, MatCardContent, MatCardHeader} from "@angular/material/card";
import {MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ResultCallStatusPipe} from "../common/pipe/result-call-status.pipe";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatOption, MatSelect} from "@angular/material/select";
import {NgForOf} from "@angular/common";
import {Router} from "@angular/router";

@Component({
  selector: 'app-contact-import',
  standalone: true,
  imports: [
    AudioPlayerComponent,
    CallStatusPipe,
    MatButton,
    MatCard,
    MatCardActions,
    MatCardContent,
    MatCardHeader,
    MatDialogContent,
    ResultCallStatusPipe,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatLabel,
    NgForOf
  ],
  templateUrl: './contact-import.component.html',
  styleUrl: './contact-import.component.scss'
})
export class ContactImportComponent {

  selectedOption: string = '';
  options = [
    { id: 1, value: 'Scnéario 1', viewValue: 'Option 1' },
    { id: 2, value: 'Scnéario 2', viewValue: 'Option 2' },
    { id: 3, value: 'Scnéario 3', viewValue: 'Option 3' }
  ];

  constructor(
    public dialogRef: MatDialogRef<ContactImportComponent>,
    public router:Router
  ) {
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      console.log('Selected file:', file.name);
    }
  }

  import(): void {
    console.log('Importing file with option:', this.selectedOption);
    this.dialogRef.close();

  }

  onBack(): void {
    this.dialogRef.close();
  }
}
