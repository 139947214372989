<div class="">
  <h2 class="mat-h2 m-3">Liste des contacts</h2>

  <div class="d-flex align-items-center justify-content-start my-3">
    <button mat-raised-button color="accent" (click)="import()">Importer une liste de contact</button>
    <button mat-raised-button color="accent" class="mx-4" (click)="startCall()">Lancer une campagne d’appels</button>
  </div>


  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 my-1">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let contact">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(contact) : null"
                      [checked]="selection.isSelected(contact)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Nom Column -->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
      <td mat-cell *matCellDef="let contact"> {{contact.lastName}} </td>
    </ng-container>

    <!-- First Name Column -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom </th>
      <td mat-cell *matCellDef="let contact"> {{contact.firstName}} </td>
    </ng-container>

    <!-- Phone Number Column -->
    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Numéro de téléphone </th>
      <td mat-cell *matCellDef="let contact"> {{contact.phoneNumber}} </td>
    </ng-container>

    <!-- Scenario Column -->
    <ng-container matColumnDef="titleScenario">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Scenari </th>
      <td mat-cell *matCellDef="let contact"> {{contact.scenario.title}} </td>
    </ng-container>

    <!-- CallStatus Column -->
    <ng-container matColumnDef="callStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut </th>
      <td mat-cell *matCellDef="let contact"> {{contact.callStatus | callStatus}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked(row)"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

</div>
