import { Routes } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {ContactListComponent} from "./contact-list/contact-list.component";
import {ScenarioComponent} from "./scenario/scenario.component";
import {CreateScenarioComponent} from "./create-scenario/create-scenario.component";
import {LoginComponent} from "./auth/login/login.component";
import {PromptComponent} from "./prompt/prompt.component";

export const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'contact',
    component: ContactListComponent,
  },
  {
    path: 'scenario',
    component: ScenarioComponent,
  },
  {
    path: 'scenario/create',
    component: CreateScenarioComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'prompt',
    component: PromptComponent,
  },
  {
    path: '**',
    redirectTo: 'home',
  }
];
