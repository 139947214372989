<div class="mat-elevation-z8 my-5 width-80">
  <form (ngSubmit)="saveSignal()" class="m-3 d-flex flex-column" >
    <mat-form-field appearance="fill" class="mt-3">
      <mat-label>prompt</mat-label>
      <textarea matInput [(ngModel)]="prompt" name="scenario" rows="6" required></textarea>
    </mat-form-field>
    <div class="d-flex justify-content-end my-2">
      <button mat-raised-button color="primary" class="mx-3" type="submit">Sauvegarder prompt</button>
    </div>
  </form>
</div>
