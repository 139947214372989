

<div class="mat-elevation-z8 my-5 width-80">


  <h3 class="mat-h2 m-3">Nouveau scénario</h3>

  <form (ngSubmit)="onSubmit()" class="m-3 d-flex flex-column" >
    <mat-form-field appearance="fill" class="width-40">
      <mat-label>Titre</mat-label>
      <input matInput [(ngModel)]="title" name="title" required>
    </mat-form-field>

    <mat-form-field appearance="fill" class="prompt">
      <mat-label>Prompt</mat-label>
      <textarea matInput [(ngModel)]="prompt" name="prompt" rows="6" required></textarea>
    </mat-form-field>

    <div class="d-flex justify-content-end my-2">
      <button mat-raised-button color="primary" class="mx-3" type="submit">Créer le scénario</button>
      <button mat-raised-button color="accent" type="button" (click)="onBack()">Retour</button>
    </div>
  </form>
</div>
